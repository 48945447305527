/* You can add global styles to this file, and also import other style files */

@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";

:root {
    --turqueza: #10B1E6;
}

.sidebar li.active>a {
    background-color: var(--turqueza) !important;
}

@media print {
    app-navbar, .sidebar, .no-print, .no-print * {
        display: none !important;
    }

    .main-panel {
        float: unset;
        width: 100%;
    }
}

.modal-backdrop {
    z-index: 0 !important;
}

.sidebar .nav li a, .sidebar .nav li .dropdown-menu a {
    padding: 0px;
}

label {
    margin-left: 8px;
    margin-top: -2px;
    color: var(--turqueza);
    font-weight: bold;
}

h3 {
    margin: 0px;
    font-weight: bold;
    color: black;
}

h4 {
    font-weight: bold;
    text-decoration: underline;
}

h6 {
    color: var(--turqueza);
}

th {
    text-align: left !important;
}

input, textarea {
    background-color: var(--turqueza);
    color: white;
    border-radius: 10px;
    border: 1px solid var(--turqueza);
    text-align: center;
    font-weight: bold;
    margin-left: 8px;
}

select2 {
    width: -webkit-fill-available;
}

select2 input {
    color: black;
    text-align: left;
    width: 95% !important;
}

.blanco {
    background-color: white;
    color: rgba(0, 0, 0, 0.5);
}

input:disabled {
    color: grey;
}

.normal {
    background-color: white;
    color: black;
    text-align: left;
    border: none;
    width: 100%;
    text-overflow: ellipsis;
}

.datoTabla {
    text-align: center;
    border: 2px solid var(--turqueza);
    width: 95%;
}

.btn {
    border-radius: 12px;
}

.btn-small {
    padding: 4px !important;
}

.btn-mid {
    padding: 6px 16px !important;
}

.btn-big {
    padding: 8px;
    height: 100px;
    width: 80px;
}

.btn-primary {
    background-color: var(--turqueza) !important;
}

.input-simbolo {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.simbolo-input {
    padding: 0px 8px;
    background-color: var(--turqueza);
    color: white;
    border-radius: 32px;
    border: 1px solid var(--turqueza);
    text-align: center;
    font-weight: bold;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.dropdown {
    display: inline-block;
}

.seccion {
    border: 2px solid var(--turqueza);
    border-radius: 18px;
    padding: 16px;
    margin: 8px;
}

.thead-sticky {
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 99;
}

.imagenPrincipal {
    border-radius: 100%;
    border: 2px solid var(--turqueza);
    width: inherit;
}

.imagenDato {
    border-radius: 100%;
    border: 2px solid var(--turqueza);
}

.seccion2 {
    padding: 16px 180px;
}

.titulo-seccion {
    font-weight: bold;
    color: white;
    background-color: var(--turqueza);
    text-align: center;
    border-radius: 64px;
    margin-bottom: 16px;
    margin-top: -16px;
}

.dato1 {
    color: grey;
    font-weight: bold;
}

.dato2 {
    font-weight: bold;
}

.dato {
    margin-right: 1rem;
    background-color: #fff;
    font-weight: 500;
    border: 2px solid var(--turqueza);
    border-radius: 10px;
    padding: 2px 8px;
    margin-bottom: 6px;
    display: inline-block;
    text-align: start;
}

.nombreDato {
    font-weight: 500;
    color: var(--turqueza);
    margin-right: 8px;
}

.valorDato {
    font-weight: bold;
    margin-left: 8px;
}

.input-group-append, .input-group-prepend {
    background-color: var(--turqueza);
    color: white;
    border-radius: 32px;
    border: 1px solid var(--turqueza);
    text-align: center;
    font-weight: bold;
    margin-left: 8px;
}

.widget-productos {
    display: inline-block;
    border: 2px solid var(--turqueza);
    border-radius: 32px;
    padding: 8px;
    height: 130px;
    width: 150px;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    margin: 5px 1px;
}

.btn-big>i {
    font-size: large !important;
}

.numero-grande {
    font-size: 32px;
}

.numero-productos {
    margin-top: -10px;
    margin-bottom: 10px;
}

.table thead th {
    font-size: 0.8rem;
    font-weight: bold;
    border-top-width: 0;
    border-bottom-width: 1px;
    padding: 1px;
    text-align: center;
}

tr.pedidoRow {
    font-size: 14px;
}

tr.pedidoRow td {
    padding: 2px 4px !important;
    border: 1px solid lightgrey;
}

/* estilos fullcalendar */

.fc .fc-highlight {
    background-color: rgb(89 89 89) !important;
}

.fc .fc-col-header-cell-cushion {
    color: white;
}

.fc .fc-daygrid-day-number {
    font-weight: 900;
}

.select2-selection__rendered li span:nth-child(2) {
    margin-left: 16px;
}

.widget-lista {
    width: 250px;
    padding: 8px;
    height: 180px;
}

.bg-verificado {
    background-color: #00ff1121;
}

.bg-pendiente {
    background-color: #ff550017;
}


/* Estilos de tablas */

tr:last-child>td:first-child {
    border-radius: 0px 0px 0px 32px;
}

tr:last-child>td:last-child {
    border-radius: 0px 0px 32px 0px;
}

.div-tabla {
    background-color: #10b1e6;
    border-radius: 32px;
}

.table {
    border-collapse: separate;
}

thead {
    background-color: #10b1e6;
    color: white;
}

th {
    text-align: center !important;
}

tr {
    background-color: white;
}

td {
    text-align: center;
    font-weight: bold;
    padding: 0px !important;
}

.table>:not(caption)>*>* {
    background-color: unset;
}

.div-tabla h4 {
    text-align: center;
    color: white;
}

td {
    padding: 3px !important;
    min-width: 20px;
}

.table thead th {
    font-size: 1rem;
}

tr.rojo>td {
    background-color: #f6c3c2;
}

tr.azul>td {
    background-color: #a1e6f5;
}

tr.verde>td {
    background-color: #aedfb5;
}

.pedido-rechazado {
    background-color: #FCBDBD !important;
}

.pedido-activo {
    background-color: #b6d3ee !important;
}

.pedido-entregado {
    background-color: #c3f8bf !important;
}

.pedido-demorado {
    color: #a60000 !important;
    font-weight: 600 !important;
}

.modificado {
    font-style: italic;
}

tr:hover>td {
    background-color: #e2e2e2 !important;
}

.header-comercio {
    padding: 32px;
    background-color: #10b1e7;
    margin: 16px;
    border-radius: 32px;
    color: white;
}

.header-comercio h3 {
    color: white;
}

.header-comercio p {
    line-height: normal;
    margin-bottom: 0.5rem;
    font-size: medium;
    font-weight: 400;
}

span.rojo {
    background-color: #e11c1e;
}

span.verde {
    background-color: #15a02b;
}

span.widget>span {
    font-size: small;
}

span.widget {
    text-align: center;
    border: 3px solid white;
    border-radius: 32px;
    padding: 0px 16px;
    display: inline-flex;
    flex-direction: column;
    font-size: larger;
    color: white;
    font-weight: 600;
}